import * as React from 'react';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import { DataGrid, useGridApiRef, GridToolbar } from '@mui/x-data-grid';
import $ from 'jquery'
import Typography from '@mui/material/Typography';
import Skeleton from '@mui/material/Skeleton';
import Divider from '@mui/material/Divider';



  
export default function Products() {

    const apiRef = useGridApiRef();

    const columns = [
        { field: 'id', headerName: 'ID', width: 20 },
        {
          field: 'title',
          headerName: 'Title',
          editable: true,
          flex: .5,
          minWidth: 100
        },
        {
            field: 'model',
            headerName: 'Model',
            editable: true,
            flex: .5,
            minWidth: 100
        },
        {
          field: 'vendor',
          headerName: 'Vendor',
          editable: true,
          flex: .5,
          minWidth: 100,
          type: "singleSelect",
          valueOptions: ["", "SS", "Amazon", "Dell"]
        },
        {
          field: 'description',
          headerName: 'Description',
          editable: true,
          flex: 1,
        },
        {
          field: 'link',
          headerName: 'Link',
          editable: true,
          flex: .5,
        },
        {
          field: 'price',
          headerName: 'Price',
          type: 'number',
          editable: true,
          flex: .25,
          valueGetter: (params) => {
            if (!params.value) {
              return params.value;
            }
            // Convert the decimal value to a percentage
            return `$${params.value}`;
          },
        },
        {
          field: 'markup',
          headerName: 'Markup',
          type: 'singleSelect',
          valueOptions: [true, false],
          editable: true,
          flex: .25
        },
        {
            field: "Delete",
            headerName: ' ',
            renderCell: (cellValues) => {
              return (
                <Button
                  onClick={(event) => {
                    handleClick(event, cellValues);
                  }}
                >
                  X
                </Button>
              );
            }
          }
      ];

      
      
      var rows = [
      ];

    
      const handleAddRow = () => {
        var rows = apiRef.current.getRowsCount()
        apiRef.current.updateRows([{id: rows + 1, title: 'new item'}]);
      };

      const handleClick = (event, cellValues) => {
        console.log(cellValues)
        apiRef.current.updateRows([{ id: cellValues.id, _action: 'delete' }])
      };

      const Save = async () => {
        $('#status').text('Saving...')
        var rows = apiRef.current.getRowsCount()
        var json = []
        for (let r = 1; r <= rows; r++) {
            json.push(apiRef.current.getRow(r))
        }
        
        console.log(json)

        var res = await fetch('https://api.jsonbin.io/v3/b/64cb00dd8e4aa6225ec9acef', {
            method: 'PUT',
            headers: {
                "X-Master-Key": sessionStorage.getItem("master"),
                "X-Access-Key": sessionStorage.getItem("key"),
                "Content-Type": "application/json"
            },
            body: JSON.stringify(json)

        })

        if (res.ok) {
            Saved()
        } else {
            $('#status').text('Error')
        }

        console.log(res)
      }

      const Saved = async () => {
        $('#status').text('Saved')
      }

      async function PullRows() {
        console.log('getting rows')
        var data = await fetch('https://api.jsonbin.io/v3/b/64cb00dd8e4aa6225ec9acef/latest', {
            method: 'GET',
            headers: {
                "X-Master-Key": sessionStorage.getItem("master"),
                "X-Access-Key": sessionStorage.getItem("key"),
                "Content-Type": "application/json"
            }
        })
        var response = await data.json()
        var newRows = response.record
        console.log('loading into table', newRows)
        try {
            for (let row of newRows) {
                apiRef.current.updateRows([row])
            }
            
        } catch (error) {
            rows = newRows
        }
        
      }

      PullRows()

      
    return (
      <Box sx={{ height: 'auto', width: '88vw' }}>
        <h1>Product Editor</h1>
        <br></br>
        <Divider></Divider>
        <br></br>
        <DataGrid
            apiRef={apiRef}
            rowRe
            rows={rows}
            columns={columns}
            autoHeight
            disableRowSelectionOnClick
            slotProps={{
              toolbar: {
                showQuickFilter: true,
                quickFilterProps: { debounceMs: 500 },
              },
            }}
            components={{
                NoRowsOverlay: () => (
                    <Box sx={{ height: 'auto', width: '88vw', padding: '30px' }}>
                        <Stack height="auto" alignItems="center" justifyContent="center">
                            Loading from database...
                        </Stack>  
                        <Typography variant="h4"><Skeleton /></Typography>          
                    </Box>
                  
                ),
                toolbar: GridToolbar 
            }}
        />
        <Button size="small" onClick={handleAddRow}>
          Add a row
        </Button>
        <br></br>
        <a href="#?" type="button" id="save" onClick={Save}  className="btn btn-primary btn-lg">Save</a>
        <p id="status"></p>
      </Box>
    );
  }


