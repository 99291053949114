import $ from 'jquery'

export default async function Scraper() {

            try {
                var uidata
            await $.getJSON("https://api.gatesweeney.com/ui/stock.json",
                function (data) {
                    uidata = data
                }
                )
            return uidata
            } catch (error) {
                console.log(error)
                alert('Couldn\'t connect to gatesweeney.com, or another error has ocurred. Check console.')
            }

}