import {React, useEffect} from 'react';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Skeleton from '@mui/material/Skeleton';
import CircleIcon from '@mui/icons-material/Circle';
import { DataGrid, useGridApiRef, GridToolbar } from '@mui/x-data-grid';
import $ from 'jquery'
import Scraper from './Scraper';


  
export default function UIStock() {

    const apiRef = useGridApiRef();
    const apiRefFav = useGridApiRef();

    
    const columnsFav = [
        { field: 'id', headerName: 'ID' },
        {
            field: 'image',
            headerName: 'Image',
            editable: false,
            width: 60,
            renderCell: (params) => <img height="50" alt="" src={params.value} />,
        },
        { field: 'category', headerName: 'Category', flex: .3 },
        { field: 'model', headerName: 'Model', flex: .5 },
        {
          field: 'title',
          headerName: 'Name',
          editable: false,
          flex: 1,
          minWidth: 100
        },
        {
          field: 'stock',
          headerName: 'Stock',
          editable: false,
          flex: .1,
          renderCell: (cellValues) => {
            if (cellValues.row.stock) {
              return (<CircleIcon sx={{color: '#8ced9c'}}/>)
            } else {
              return (<CircleIcon sx={{color: '#ed8c8c'}}/>)
            }
          }
        },
        {
          field: 'price',
          headerName: 'Price',
          type: 'number',
          editable: false,
          flex: .25,
          minWidth: 100,
          valueGetter: (params) => {
            if (!params.value) {
              return params.value;
            }
            // Convert the decimal value to a percentage
            return `$${params.value}`;
          },
        },
        {
            field: " ",
            renderCell: (cellValues) => {
              return (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={(event) => {
                    window.open(cellValues.row.link)
                  }}
                >
                  Visit
                </Button>
              );
            }
          }
      ];

    var rowsFav = [
    ];
    
    const columns = [
        { field: 'id', headerName: 'ID' },
        {
            field: 'image',
            headerName: 'Image',
            editable: false,
            width: 60,
            renderCell: (params) => <img height="50" alt="" src={params.value} />,
        },
        { field: 'category', headerName: 'Category', flex: .3 },
        { field: 'model', headerName: 'Model', flex: .5 },
        {
          field: 'title',
          headerName: 'Name',
          editable: false,
          flex: 1,
          minWidth: 100
        },
        {
          field: 'stock',
          headerName: 'Stock',
          editable: false,
          flex: .1,
          renderCell: (cellValues) => {
            if (cellValues.row.stock) {
              return (<CircleIcon sx={{color: '#8ced9c'}}/>)
            } else {
              return (<CircleIcon sx={{color: '#ed8c8c'}}/>)
            }
          }
        },
        {
          field: 'price',
          headerName: 'Price',
          type: 'number',
          editable: false,
          flex: .25,
          minWidth: 100,
          valueGetter: (params) => {
            if (!params.value) {
              return params.value;
            }
            // Convert the decimal value to a percentage
            return `$${params.value}`;
          },
        },
        {
            field: " ",
            renderCell: (cellValues) => {
              return (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={(event) => {
                    window.open('https://store.ui.com' + cellValues.row.link)
                  }}
                >
                  Visit
                </Button>
              );
            }
          }
      ];

    var rows = [
    ]

    const getFavs = async (data) => {
      let res
        if (data) {
            res = await fetch('https://api.jsonbin.io/v3/b/64cc689c9d312622a38b879e', {
            method: 'PUT',
            headers: {
                "X-Master-Key": sessionStorage.getItem("master"),
                "X-Access-Key": sessionStorage.getItem("key"),
                "Content-Type": "application/json"
            },
            body: JSON.stringify(data)
        })
        } else {
            res = await fetch('https://api.jsonbin.io/v3/b/64cc689c9d312622a38b879e', {
                method: 'GET',
                headers: {
                    "X-Master-Key": sessionStorage.getItem("master"),
                    "X-Access-Key": sessionStorage.getItem("key"),
                    "Content-Type": "application/json"
                },
            })        }
        
        var response = await res.json()
        return response
    }

    const addFav = async () => {
      $('#add').text('Adding')
        var result = await getFavs()
        var submission = result.record
        console.log('prior', submission)
        var selects = apiRef.current.getSelectedRows()
        
        for (let entry of selects) {
            
            var found = false
            for (let item of submission) {
                if (item.model === entry[1].model) {found = true}
            }
            if (!found) { submission.push({model: entry[1].model}) }
        }

        console.log('new', submission)

        var res = await getFavs(submission)
        console.log(res)

        $('#add').text('Added')
        setTimeout(() => {$('#add').text('Add to favorites')}, 2000)
        setTimeout(() => {window.location.reload(true)}, 500)

        
    }

    const removeFav = async () => {
        $('#remove').text('Removing')
        var result = await getFavs()
        var submission = result.record
        var selects = apiRefFav.current.getSelectedRows()
        console.log('curr', submission, 'new', selects)
        
        for (let c = 0; c < submission.length; c++) {
            for (let entry of selects) {
                if (entry[1].model === submission[c].model) {
                    console.log('removed', entry[1].model)
                    submission.splice(c, 1)
                }
            }
        }

        if (submission.length === 0) {
            submission.push({model: 'empty'})
        }

        var res = await getFavs(submission)
        console.log(res)

        $('#remove').text('Removed')
        setTimeout(() => {$('#remove').text('Remove from favorites')}, 2000)
        setTimeout(() => {window.location.reload(true)}, 500)
    }

    async function PullFavorites() {
        var result = await getFavs()
        var favs = result.record

        var ui = await Scraper()
        var products = ui.products

        for (let product of products) {
            for (let f = 0; f < favs.length; f++) {
                if (product.model === favs[f].model) {
                    product.id = f
                    try {
                        apiRefFav.current.updateRows([product])
                    } catch (error) {
                        console.log(error)
                    }
                }
            }
        }
        
    }

    

    async function PullRows() {

        var data = await Scraper()
        console.log('got ui file', data)
        var products = data.products
        var updated = data.updated

        $('#updated').text(`Updated: ${new Date(updated).toLocaleDateString('en-us', { weekday:"long", year:"numeric", month:"short", day:"numeric", hour: "numeric", minute: "numeric", second:"numeric"})}`)


        for (let p = 1; p < products.length; p++) {
            if (!products[p].category) {
                var category = 'other'
                try {
                    var str = products[p].link.split('category/')[1]
                    try {
                        category = str.split('-')[0]
                    } catch (error) {
                        category = str.split('/')[0]
                    }
                } catch (error) {
                    category = 'other'
                }
                products[p].category = category
            }
            products[p].id = p
            apiRef.current.updateRows([products[p]])
        }
            
    }

    

    useEffect(() => {
        PullRows()
        PullFavorites()
      });

    return (
        <Box sx={{ height: 'auto', width: '88vw', minHeight: '800px' }}>
            <h1>UI Stock</h1>
            <p id="updated"></p>
            <br></br>
            <h4>Favorites</h4>
            <p>Favorites will be monitored by the backend for updates. TODO</p>
            <Button id="remove" variant="contained" onClick={removeFav}>Remove from Favorites</Button>
            <br></br>
            <br></br>
            <DataGrid
                apiRef={apiRefFav}
                rows={rowsFav}
                columns={columnsFav}
                autoHeight
                disableRowSelectionOnClick
                checkboxSelection
                sx={{
                    boxShadow: 1,
                    border: .5,
                    borderColor: 'primary.light',
                    '& .MuiDataGrid-cell:hover': {
                      color: 'primary.main',
                    },
                    textTransform: "capitalize",
                  }}
                components={{
                    NoRowsOverlay: () => (
                        <Box sx={{ height: 'auto', width: '88vw', padding: '30px' }}>
                            <Stack height="auto" alignItems="center" justifyContent="center">
                                Loading favorites...
                            </Stack>  
                            <Typography variant="h4"><Skeleton /></Typography>          
                        </Box>
                      
                    ),
                    toolbar: GridToolbar 
                }}
                slotProps={{
                    toolbar: {
                      showQuickFilter: true,
                      quickFilterProps: { debounceMs: 500 },
                    },
                  }}
                initialState={{
                  columns: {
                      columnVisibilityModel: {
                      // Hide columns status and traderName, the other columns will remain visible
                      id: false,
                      },
                  },
                  sorting: {
                    sortModel: [{ field: 'category', sort: 'asc' }],
                  },
                }}
            />
            <br></br>
            <h4>All Items</h4>
            <p>All UniFi and UISP items and their status</p>
            <Button id="add" variant="contained" onClick={addFav}>Add to Favorites</Button>
            <br></br>
            <br></br>
            <DataGrid
                apiRef={apiRef}
                rows={rows}
                columns={columns}
                autoHeight
                disableRowSelectionOnClick
                checkboxSelection
                sx={{
                    boxShadow: 1,
                    border: .5,
                    borderColor: 'primary.light',
                    '& .MuiDataGrid-cell:hover': {
                      color: 'primary.main',
                    },
                    textTransform: "capitalize",
                  }}
                components={{
                    NoRowsOverlay: () => (
                        <Box sx={{ height: 'auto', width: '88vw', padding: '30px' }}>
                            <Stack height="auto" alignItems="center" justifyContent="center">
                                Loading initial results from scraper. This may take some time.
                            </Stack>  
                            <Typography variant="h4"><Skeleton /></Typography>          
                        </Box> 
                    ),
                    toolbar: GridToolbar 
                }}
                slotProps={{
                    toolbar: {
                      showQuickFilter: true,
                      quickFilterProps: { debounceMs: 500 },
                    },
                  }}
                initialState={{
                  columns: {
                      columnVisibilityModel: {
                      // Hide columns status and traderName, the other columns will remain visible
                      id: false,
                      },
                  },
                  sorting: {
                    sortModel: [{ field: 'category', sort: 'asc' }],
                  },
                }}
            />
            <br></br>
            
        </Box>
        );
      
    
  }





