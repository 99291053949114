import React from "react";
import './App.css'
import { BrowserRouter as Router, Routes, Route } from "react-router-dom"; // Removed "Link"
import $ from 'jquery'


import Dashboard from "./Dashboard";
import Products from "./Products";
import Quotes from "./Quotes";
import UIStock from "./UIStock";
import EditQuote from "./EditQuote";


function App() {

  document.title = "SS Quoting"

  if (!sessionStorage.getItem("master") && window.location.pathname !== "/") {
    window.location.href = "/"
  }

  return (
    <Router>
      <Navtop />
      <div className="page-wrap">
      {/*<Sidebar />*/}
      <div className="main-content">
      <Routes>
        <Route path="/">
          <Route exact path='/' element={<Home/>}/>
        </Route>
        <Route path="/dashboard">
          <Route exact path='/dashboard' element={<Dashboard/>}/>
        </Route>
        <Route path="/products">
          <Route exact path='/products' element={<Products/>}/>
        </Route>
        <Route path="/quotes">
          <Route exact path='/quotes' element={<Quotes/>}/>
        </Route>
        <Route path="/quotes/:id" element={<EditQuote />} />
        <Route path="/uistock">
          <Route exact path='/uistock' element={<UIStock/>}/>
        </Route>
      </Routes>
      </div>
      </div>
    </Router>
  );
}

export default App;


function Navtop() {
  return (
    <nav className="navbar navbar-expand-lg bg-light">
          <div className="container-fluid">
            <a className="navbar-brand" href="/">
              <img src="https://uploads-ssl.webflow.com/637682456e210572cc67f470/6376825799674216310c606a_SweeneySystems-Logo-01.png" height="60" className="d-inline-block align-top" alt="logo" loading="lazy"></img>
            </a>
            <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
              <span className="navbar-toggler-icon"></span>
            </button>
            <div className="collapse navbar-collapse" id="navbarSupportedContent">
              <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                <li className="nav-item">
                  <a className="nav-link active" aria-current="page" href="/">Home</a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="/quotes">Quotes</a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="/products">Products</a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="/uistock">UI Stock</a>
                </li>
              </ul>
            </div>
          </div>
        </nav>
  )
}


function Home() {

  $(document).keypress(function(event) {
    if (event.key === "Enter") {
        GetKey()
    }
  });

  async function GetKey() {
    console.log('password button submitted')
    var password = $('#password').val()
    var url = 'https://api.gatesweeney.com/api/quoteauth?token=' + password
    console.log(url)
    await $.getJSON(url,
      function (data) {
        console.log(data)
        if (!data.unauthorized) {
          sessionStorage.setItem("master", data.master)
          sessionStorage.setItem("key", data.key)
          Redirect()
        }
      }
    )
  }

  async function Redirect() {
    if (sessionStorage.getItem("master")) {
      window.location.href = "/dashboard"
    }
  }

  Redirect()

  return (
    <div>
      <title>RAPT TOOLS</title>
      <h1 className="display-3">Welcome to the Sweeney Systems Quoting Portal</h1>
      <p className="display-5">Please Enter the six digit OTP to use the site.</p>
      <div className="flex-horiz">
        <input id="password"></input>
        <a href="#?" type="button"id="psubmit" onClick={GetKey} className="btn btn-primary btn-lg">Submit</a>
      </div>
    </div>
  )
}



