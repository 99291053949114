import React from "react";
import './App.css'
import $, { getJSON } from 'jquery'

export default function Dashboard() {
    return (
        <div>
          <title>RAPT TOOLS</title>
          <h1 className="display-3">Dashboard</h1>
          <div className="flex-horiz">
            <a type="button" href="/quotes"  className="btn btn-primary btn-lg">View Quotes</a>
            <a type="button" href="/products"  className="btn btn-primary btn-lg">Edit Products</a>
            <a type="button" href="/uistock"  className="btn btn-primary btn-lg">UI Stock</a>
          </div>
        </div>
      )
  }