import * as React from 'react';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import { DataGrid, useGridApiRef, GridToolbar } from '@mui/x-data-grid';
import $ from 'jquery'
import Typography from '@mui/material/Typography';
import Skeleton from '@mui/material/Skeleton';
import Divider from '@mui/material/Divider';


  
export default function Quotes() {

    const apiRef = useGridApiRef();

    const columns = [
        { field: 'id', headerName: 'ID', width: 20 },
        {
          field: 'customer',
          headerName: 'Customer',
          editable: true,
          flex: .5,
          minWidth: 100
        },
        {
            field: 'jobname',
            headerName: 'Job Name',
            editable: true,
            flex: .5,
            minWidth: 100
          },
        {
          field: 'description',
          headerName: 'Description',
          editable: true,
          flex: 1,
        },
        {
          field: 'price',
          headerName: 'Price',
          type: 'number',
          editable: false,
          flex: .25,
          valueGetter: (params) => {
            if (!params.value) {
              return params.value;
            }
            // Convert the decimal value to a percentage
            return `$${params.value}`;
          },
        },
        {
            field: "open",
            headerName: '',
            renderCell: (cellValues) => {
              return (
                <Button
                  id={cellValues.id}
                  variant="contained"
                  color="primary"
                  onClick={(event) => {
                    handleOpen(event, cellValues);
                  }}
                >
                  Open
                </Button>
              );
            }
          },
          {
            field: "delete",
            headerName: '',
            renderCell: (cellValues) => {
              return (
                <Button
                  id={cellValues.id}
                  onClick={(event) => {
                    handleDel(event, cellValues);
                  }}
                >
                  Delete
                </Button>
              );
            }
          }
      ];

      
      
    const [rows, updateRows] = React.useState([])


    const handleAddRow = () => {
    var rows = apiRef.current.getRowsCount()
    apiRef.current.updateRows([{id: rows + 1, item: 'new item'}]);
    };

    const handleDel = (event, cellValues) => {
      console.log(cellValues)
      apiRef.current.updateRows([{ id: cellValues.id, _action: 'delete' }])
    };

    const handleOpen = async (event, cellValues) => {
      $(`#${cellValues.id}`).text('Loading')
      await Save()
      window.location.href = `/quotes/${cellValues.id}`
    };

    const Save = async () => {
        $('#save').text('Saving...')
        var rows = apiRef.current.getRowsCount()
        var json = []
        for (let r = 1; r <= rows; r++) {
            json.push(apiRef.current.getRow(r))
        }

        for (let quote of json) {
            if (!quote.products) {
                quote.products = [{id: 1, item: 'No Products'}]
            }
        }
        
        console.log(json)

        var res = await fetch('https://api.jsonbin.io/v3/b/64cb95d5b89b1e2299ca9847', {
            method: 'PUT',
            headers: {
                "X-Master-Key": sessionStorage.getItem("master"),
                "X-Access-Key": sessionStorage.getItem("key"),
                "Content-Type": "application/json"
            },
            body: JSON.stringify(json)

        })

        if (res.ok) {
            $('#save').text('Saved')
            setTimeout(() => {$('#save').text('Save')}, 2000)
        } else {
            $('#status').text('Error')
        }

        console.log(res)
    }

    async function PullRows() {
        console.log('getting rows')
        var data = await fetch('https://api.jsonbin.io/v3/b/64cb95d5b89b1e2299ca9847', {
            method: 'GET',
            headers: {
                "X-Master-Key": sessionStorage.getItem("master"),
                "X-Access-Key": sessionStorage.getItem("key"),
                "Content-Type": "application/json"
            }
        })
        var response = await data.json()
        var newRows = response.record
        console.log(newRows)
        
        updateRows(newRows)
    
    }

    React.useEffect(() => {
      PullRows()
    }, [])

    return (
        <Box sx={{ height: 'auto', width: '88vw' }}>
            <h1>Quotes</h1>
            <br></br>
            <Divider></Divider>
            <br></br>
            <DataGrid
                apiRef={apiRef}
                rows={rows}
                columns={columns}
                autoHeight
                disableRowSelectionOnClick
                components={{
                    NoRowsOverlay: () => (
                        <Box sx={{ height: 'auto', width: '88vw', padding: '30px' }}>
                            <Stack height="auto" alignItems="center" justifyContent="center">
                                Loading from database...
                            </Stack>  
                            <Typography variant="h4"><Skeleton /></Typography>          
                        </Box>
                      
                    ),
                    toolbar: GridToolbar 
                }}
            />
            <Button size="small" onClick={handleAddRow}>
            Add a row
            </Button>
            <br></br>
            <a href="#?" type="button" id="save" onClick={Save}  className="btn btn-primary btn-lg">Save</a>
        </Box>
        );
      
    
  }


