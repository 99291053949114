import {React, useEffect, useState} from 'react';
import { useLocation } from 'react-router-dom';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import CircleIcon from '@mui/icons-material/Circle';
import Stack from '@mui/material/Stack';
import { DataGrid, useGridApiRef, GridToolbar, useGridApiContext } from '@mui/x-data-grid';
import $ from 'jquery'
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Skeleton from '@mui/material/Skeleton';
import Divider from '@mui/material/Divider';
import Scraper from './Scraper';
import { Autocomplete, InputBase } from '@mui/material';
import './index.css'


// Searchable field code
// https://github.com/mui/mui-x/tree/master/packages/grid/x-data-grid-generator/src

//https://stackoverflow.com/questions/68649453/how-to-dynamically-add-the-valueoptions-of-a-singleselect-column-in-material-ui


// MUI value autocomplete issue https://github.com/mui/material-ui/issues/29727



export default function EditQuote() {

  const location = useLocation()
  const id = location.pathname.split('quotes/')[1]
  const apiRef = useGridApiRef();

  const [total, updateTotal] = useState([0])
  const [customer, updateCustomer] = useState([])
  const [quoteDescription, updateQuoteDescription] = useState([])
  const [quoteName, updateQuoteName] = useState([])
  

  const TotalField = () => {
    return (
      <Typography variant='h4'>Total: ${total}</Typography>
    )
  }

  const antiClose = () => {
    var message = "Not saved";
        window.onbeforeunload = function(event) {
            var e = event || window.event;
            if (e) {
                e.returnValue = message;
            }
            return message;
        };
  }


  const [rows, setRows] = useState([])
  const [columns, setColumns] = useState([
          { field: 'id', headerName: 'ID', width: 20 },
          {
            field: 'title',
            headerName: 'Title',
          },
          {
              field: 'model',
              headerName: 'Model',
              editable: true,
              flex: .5,
              minWidth: 100
            },
          { field: 'description', headerName: 'Description', editable: true, flex: 1, },
          {
            field: 'stock',
            headerName: 'Stock',
            editable: false,
            flex: .1,
            renderCell: (cellValues) => {
              if (cellValues.row.stock) {
                return (<CircleIcon sx={{color: '#8ced9c'}}/>)
              } else if (cellValues.row.stock === false){
                return (<CircleIcon sx={{color: '#ed8c8c'}}/>)
              } else {
                return
              }
            }
          },
          { field: 'quantity', headerName: 'Quantity', editable: true, type: 'number', flex: .25, },
          {
            field: 'price',
            headerName: 'Price',
            type: 'number',
            editable: true,
            flex: .25,
          },
          {
            field: 'markup',
            headerName: 'Markup',
          },
          {
            field: 'total',
            headerName: 'Total',
            type: 'number',
            editable: false,
            flex: .25,
            valueGetter: (cellValues) => {
              //console.log('total', cellValues)
              return cellValues;
            },
          },
          {
              field: "Delete",
              headerName: ' ',
              
            }])

    
      const handleAddRow = () => {
        var rows = apiRef.current.getRowsCount()
        apiRef.current.updateRows([{id: rows + 1, description: 'new item'}]);
      };

      const Save = async () => {
        var data = await fetch('https://api.jsonbin.io/v3/b/64cb95d5b89b1e2299ca9847', {
            method: 'GET',
            headers: {
                "X-Master-Key": sessionStorage.getItem("master"),
                "X-Access-Key": sessionStorage.getItem("key"),
                "Content-Type": "application/json"
            }
        })

        var rc = apiRef.current.getRowsCount()

        var currProducts = []

        for (let i = 1; i <= rc; i ++) {
          currProducts.push(apiRef.current.getRow(i))
        }

        var oq = await data.json()

        for (let q = 0; q < oq.record.length; q++) {
          var curr = oq.record[q]
          if (parseInt(curr.id) === parseInt(id)) {
            console.log('match', oq.record[q])
            curr.products = currProducts
            curr.customer = customer[0]
            curr.description = quoteDescription[0]
            curr.jobname = quoteName[0]
            curr.price = total[0]
          }
        }

        var res = await fetch('https://api.jsonbin.io/v3/b/64cb95d5b89b1e2299ca9847', {
            method: 'PUT',
            headers: {
                "X-Master-Key": sessionStorage.getItem("master"),
                "X-Access-Key": sessionStorage.getItem("key"),
                "Content-Type": "application/json"
            },
            body: JSON.stringify(oq.record)

        })

        if (res.ok) {
          $('#status').text('Saved')
        } else {
            $('#status').text('Error')
        }

        console.log(res)
        window.onbeforeunload = null

      }

      async function updatedRow(ur) {  
        
        var rc = apiRef.current.getRowsCount()
        var tot = 0
        for (let i = 1; i <= rc; i++) {
          var r = apiRef.current.getRow(i)
          var q = r.quantity
          var p = r.price
          var m = r.markup
              
          if (q && p) {
            var t = (parseFloat(q) * parseFloat(p))
            if (m) {t = t * 1.2}
            tot = tot + t
          }
        }

        updateTotal([tot.toFixed(2)])

        antiClose()
        
        
      }

      const getFavs = async (data) => {
        if (data) {
            await fetch('https://api.jsonbin.io/v3/b/64cc689c9d312622a38b879e', {
            method: 'PUT',
            headers: {
                "X-Master-Key": sessionStorage.getItem("master"),
                "X-Access-Key": sessionStorage.getItem("key"),
                "Content-Type": "application/json"
            },
            body: JSON.stringify(data)
        })
        } else {
            var res2 = await fetch('https://api.jsonbin.io/v3/b/64cc689c9d312622a38b879e', {
                method: 'GET',
                headers: {
                    "X-Master-Key": sessionStorage.getItem("master"),
                    "X-Access-Key": sessionStorage.getItem("key"),
                    "Content-Type": "application/json"
                },
            })        }
        
        var response = await res2.json()
        return response
    }

    async function GetQuotes() {
      console.log('getting rows')
      var data = await fetch('https://api.jsonbin.io/v3/b/64cb95d5b89b1e2299ca9847', {
          method: 'GET',
          headers: {
              "X-Master-Key": sessionStorage.getItem("master"),
              "X-Access-Key": sessionStorage.getItem("key"),
              "Content-Type": "application/json"
          }
      })
      var response = await data.json()
      return response
  
    }

      

      async function PullRows(id) {
        sessionStorage.setItem('products', JSON.stringify(await GetProducts()))
        var data = await fetch('https://api.jsonbin.io/v3/b/64cb95d5b89b1e2299ca9847', {
            method: 'GET',
            headers: {
                "X-Master-Key": sessionStorage.getItem("master"),
                "X-Access-Key": sessionStorage.getItem("key"),
                "Content-Type": "application/json"
            }
        })
        var response = await data.json()
        var allQuotes = response.record
        for (let quote of allQuotes) {
          if (parseInt(id) === parseInt(quote.id)) {
            var currQuote = quote
            
          }
        }

        updateQuoteName([currQuote.jobname])
        updateCustomer([currQuote.customer])
        updateQuoteDescription([currQuote.description])

        if (!currQuote.products) {currQuote.products = [{id: 1, item: 'No Products'}]}

        try {
          setRows(currQuote.products)
        } catch (error) {
          console.log('quote products', currQuote.products)
          console.log(error)
        }
        
      }

      var allProducts = []

      async function GetProducts() {

        // Internal Products
        var dataInternal = await fetch('https://api.jsonbin.io/v3/b/64cb00dd8e4aa6225ec9acef/latest', {
            method: 'GET',
            headers: {
                "X-Master-Key": sessionStorage.getItem("master"),
                "X-Access-Key": sessionStorage.getItem("key"),
                "Content-Type": "application/json"
            }
        })
        var responseInternal = await dataInternal.json()
        var internalProducts = responseInternal.record
        try {
            for (let row of internalProducts) {
                allProducts.push(row)
            }
            
        } catch (error) {
          console.log(error)
        }

        // UI Products

        var uiProducts = await Scraper() 
        var favs = await getFavs()

        var titles =[]

        for (let ui of uiProducts.products) {
          for (let fav of favs.record) {
            if (ui.model === fav.model) {
              allProducts.push(ui)
              titles.push(ui.title)
            }
          }
        }

        console.log('setting columns', allProducts)
        
        
        setColumns([
          { field: 'id', headerName: 'ID', width: 20 },
          {
            field: 'title',
            headerName: 'Title',
            type: 'singleSelect',
            valueOptions: allProducts,
            getOptionValue: (value) => value.title ?? '',
            getOptionLabel: (value) => value.title ?? '',
            valueFormatter: (o) => o?.value,
            flex: 1,
            minWidth: 150,
            editable: true,
            valueGetter: (o) => {
              var model = o.row.model
              for (let p of allProducts) {
                if (p.model === model) {
                  return p.title
                }
              }
            },
            renderEditCell: (params) => {
              return (
                <Autocomplete
                value={allProducts}
                options={allProducts}
                getOptionSelected={(option, value) => option.title === value.title}
                isOptionEqualToValue={(option, values) => {
                  for (let v of values) {
                    if (option.model === v.model) {
                      return true
                    } else {return false}
                  }
                }}
                onChange={async (event, v) => {
                  //change row column values
                  v.id = params.id
                  if (!v.quantity) {v.quantity = 1}
                  v.description = v.title
                  apiRef.current.updateRows([v])

                }}
                getOptionLabel={(o) => String(o.title)}
                autoHighlight
                autoComplete
                autoSelect
                fullWidth
                open
                disableCloseOnSelect={false}
                disableClearable
                freeSolo
                renderOption={(optionProps, option) => {
                  var InStock = () => {
                    if (option.stock) {
                      return (<CircleIcon sx={{color: '#8ced9c', marginRight: '8px'}}/>)
                    } else if (typeof option.stock === 'boolean' && option.stock === false) {
                      return (<CircleIcon sx={{color: '#ed8c8c', marginRight: '8px'}}/>)
                    } else if (option.stock === undefined) {
                      return (<CircleIcon sx={{color: '#bfbfbf', marginRight: '8px'}}/>)
                    }
                  }

                  return (
                      <Box
                        component="li"
                        sx={{
                          '& > img': {
                            mr: 1,
                            flexShrink: 0,
                          },
                        }}
                        {...optionProps}
                      >
                        <InStock></InStock>{option.title}
                      </Box>
                    
                  )
                }}
                renderInput={(params) => (
                  <InputBase
                    autoFocus
                    fullWidth
                    id={params.id}
                    inputProps={{
                      ...params.inputProps,
                      autoComplete: 'new-password', // disable autocomplete and autofill
                    }}
                    {...params.InputProps}
                  />
                )}
              />
              )
          }},
          { field: 'model', headerName: 'Model', editable: true, flex: .5, minWidth: 100 },
          { field: 'description', headerName: 'Description', editable: true, flex: 1, minWidth: 200},
          {
            field: 'stock',
            headerName: 'Stock',
            editable: false,
            flex: .1,
            renderCell: (cellValues) => {
              if (cellValues.row.stock) {
                return (<CircleIcon sx={{color: '#8ced9c'}}/>)
              } else if (cellValues.row.stock === false){
                return (<CircleIcon sx={{color: '#ed8c8c'}}/>)
              } else {
                return
              }
            }
          },
          { 
            field: 'quantity',
            headerName: 'Quantity',
            editable: true,
            type: 'number',
            flex: .25, 
            minWidth: 80,
            valueFormatter: (params) => {
              return parseInt(params.value);
            },
          },
          {
            field: 'price',
            headerName: 'Price',
            type: 'number',
            editable: true,
            flex: .25,
            minWidth: 100,
            valueFormatter: (params) => {
              if (!params.value) {
                return '';
              }
              // Convert the decimal value to a percentage
              return `$${params.value}`;
            },
          },
          {
            field: 'markup', headerName: 'Markup', type: "singleSelect", valueOptions: [true, false], editable: true, minWidth: 100 },
          {
            field: 'total',
            headerName: 'Total',
            type: 'number',
            editable: false,
            flex: .25,
            minWidth: 80,
            valueGetter: (cellValues) => {
              var r = cellValues.row
              var q = r.quantity
              var p = r.price
              var m = r.markup
              
              if (q && p) {
                var total = (parseFloat(q) * parseFloat(p))
                if (m) {total = total * 1.2}
                return total.toFixed(2);
              }
            },
            valueFormatter: (params) => {
              if (params.value == null) {
                return '';
              }
              return `$${params.value.toLocaleString()}`;
            },
          },
          {
            field: "delete",
            headerName: '',
            renderCell: (cellValues) => {
              return (
                <Box>
                  <Button
                  id={cellValues.id}
                  onClick={(event) => {
                    window.open(cellValues.row.link)
                  }}
                >
                  O
                </Button>
                <Button
                  id={cellValues.id}
                  onClick={(event) => {
                    try {
                      apiRef.current.updateRows([{ id: cellValues.id, _action: 'delete' }])
                    } catch (error) {
                      console.log(error)
                      alert('Error. You may not delete the first row in the grid. If this is unexpected, please check the console.')
                    }
                  }}
                >
                  X
                </Button>
                </Box>
              );
            }
          }
        ])

        

        console.log('all products', allProducts)        
        return allProducts

      }

      useEffect(() => {
        PullRows(id)
      }, [])


      

      
    return (
      <Box>
        <Box id="content" className="page-wrapper-box" sx={{width: "100vw"}}>
          <Button href="/quotes">
            Back to Quotes
          </Button>
          <h1>#{id}: {quoteName}</h1>
          <a href="#?" type="button" id="save" onClick={Save}  className="btn btn-primary btn-lg">Save</a>
          <p id="status"></p>
          <br></br>
          <Divider></Divider>
          <br></br>
          <Typography variant='h4'>Quote Name:</Typography>
          <TextField
            onChange={(o) => updateQuoteName([o.target.value])}
            variant='standard'
            sx={{marginBottom: '20px'}}
            defaultValue={quoteName}
            placeholder={quoteName}
          /><br></br>
          <Typography variant='h6'>Customer:</Typography>
          <TextField
            onChange={(o) => updateCustomer([o.target.value])}
            variant='standard'
            sx={{marginBottom: '20px'}}
            defaultValue={customer}
            placeholder={customer}
            size='small'
          /><br></br>
          <Typography variant='p'>Quote Description:</Typography>
          <br></br>
          <TextField
            onChange={(o) => updateQuoteDescription([o.target.value])}
            variant='standard'
            sx={{marginBottom: '20px', minWidth: '50%'}}
            defaultValue={quoteDescription}
            placeholder={quoteDescription}
            size='small'
          /><br></br>
          <DataGrid
              apiRef={apiRef}
              rowRe
              rows={rows}
              columns={columns}
              autoHeight
              disableRowSelectionOnClick
              onProcessRowUpdateError={(error) => {console.log(error)}}
              onStateChange={(u) => updatedRow(u)}
              components={{
                  NoRowsOverlay: () => (
                      <Box sx={{ height: 'auto', width: '88vw', padding: '30px' }}>
                          <Stack height="auto" alignItems="center" justifyContent="center">
                              Loading from database...
                          </Stack>  
                          <Typography variant="h4"><Skeleton /></Typography>          
                      </Box>
                    
                  ),
                  toolbar: GridToolbar 
              }}
              
          />
          <Button size="small" onClick={handleAddRow}>
            Add a row
          </Button>
          <br></br>
          <br></br>
          <TotalField></TotalField>
        </Box>
      </Box>
    );
  }


